import { ComethWallet } from '@cometh/connect-sdk'
import { MetaTransaction } from 'ethers-multisend'
import { TransactionReceipt } from '@ethersproject/abstract-provider/src.ts'

export type Logger = (message: string, isError?: boolean) => void

export interface ResponseWalletDTO {
	address: string
	chainId: number
}

export interface RequestSignMessageDTO {
	message: string
}

export interface RequestSendTransactionDTO {
	metaTransaction: MetaTransaction
}

export interface RequestGetTransactionDTO {
	safeTxHash: string
}

export interface ResponseErrorDTO {
	message: string
}

export interface ResponseSignMessageDTO {
	message: string
	signature: string
}

export interface ResponseSendTransactionDTO {
	safeTxHash: string
}

export interface ResponseGetTransactionDTO {
	txReceipt: TransactionReceipt
}

export interface EventResponseDTO {
	type: EventType
	data: ResponseErrorDTO | any
	success: boolean
}

export interface EventRequestDTO {
	type: EventType
	data: any
}

type MessageEventReq = MessageEvent<EventRequestDTO>
export type MessageEventResp = MessageEvent<EventResponseDTO>

export enum EventType {
	WALLET_READY = 'WALLET_READY',
	SIGN_MESSAGE = 'SIGN_MESSAGE',
	SEND_TRANSACTION = 'SEND_TRANSACTION',
	GET_TRANSACTION = 'GET_TRANSACTION'
}

export interface EventHandler {
	action: (logger: Logger, wallet: ComethWallet, data: any) => Promise<any>
	validator: (data: any) => boolean
}