import React, { useEffect, useState } from 'react'
import api from '../sdk/api'
import { useLocation } from 'react-router-dom'
import useWallet from '../services/useWallet'
import { PublicClientInfo } from '../sdk/apiTypes'
import images from '../resources/images'

function Login() {
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const uid = queryParams.get('uid')
	const clientId = queryParams.get('client_id')
	const [error, setError] = useState<string | null>(null)
	const [clientInfo, setClientInfo] = useState<PublicClientInfo | null>(null)
	const wallet = useWallet(clientInfo?.chain_id || null, clientId)

	useEffect(() => {
		if (!clientId) {
			setError('Missing client_id in the query parameters')
			return
		}
		if (!uid) {
			setError('Missing uid in the query parameters')
			return
		}
		api.getPublicClientInfo(clientId).then((info) => {
			setClientInfo(info)
		})
	}, [])

	useEffect(() => {
		if (wallet != null) {
			signIn()
				.then()
				.catch((e) => {
					setError(e.message)
				})
		}
	}, [wallet])

	const signIn = async () => {
		if (!wallet) {
			throw new Error('No wallet')
		}
		if (!clientInfo) {
			throw new Error('No clientInfo')
		}
		if (!uid) {
			throw new Error('No uid')
		}
		const walletAddress = wallet.getAddress()
		const message =
			'Signing-in to Cometh for ' + clientInfo.client_name + ' at ' + new Date().toISOString()
		const signature = await wallet.signMessage(message)
		await api.login(uid, walletAddress, message, signature)
	}

	return (
		<div className="bg-no-repeat bg-cover bg-center font-satoshi bg-white flex-grow w-full h-screen items-center flex justify-center flex-wrap flex-col">
			<div className="w-96 text-center bg-white rounded-20 text-grey flex h-100 flex-col p-12 shadow-lg shadow-white">
				<img
					height="72"
					width="72"
					className="self-center mb-8"
					src={images.logo}
					alt="Cometh logo"
				/>
				<h5>Welcome</h5>
				<span className="text-xs">
					Sign-In with Cometh{' '}
					{clientInfo ? 'to continue to ' + clientInfo.client_name : ''}
				</span>
				{error && <div className="text-red-500 mt-4">{error}</div>}
				{!error && (
					<div className="h-12 border hover:scale-105 justify-evenly shadow-xl border-gray-50 mt-4 duration-100 ease-in-out transition-all transform flex items-center">
						<img src={images.logo} alt="Cometh logo" width="20px" height="20px" />
						<p className="font-bold">Signing-In with Cometh</p>
						<div className="loader"></div>
					</div>
				)}
			</div>
		</div>
	)
}

export default Login
