import React, { useEffect, useState } from 'react'
import useWallet from '../services/useWallet'
import { useLocation } from 'react-router-dom'
import api from '../sdk/api'
import useCommunicationHandler, {
	notifyWalletFailureToParent,
	notifyWalletReadyToParent
} from '../services/useCommunicationHandler'
import { PrivateClientInfo } from '../sdk/apiTypes'

function WebWallet() {
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const clientId = queryParams.get('apiKey')
	const [clientInfo, setClientInfo] = useState<PrivateClientInfo | null>(null)
	const logger = (log: string, isError?: boolean) => {
		if (isError) {
			console.error(log)
		} else {
			console.log(log)
		}
	}
	const wallet = useWallet(clientInfo?.chain_id || null, clientId, clientInfo?.account_wallet, logger)
	const communicationHandler = useCommunicationHandler(wallet, clientInfo, logger)

	useEffect(() => {
		if (!clientId) {
			return
		}
		api.getPrivateClientInfo(clientId)
			.then((info) => {
				setClientInfo(info)
			})
			.catch((e) => {
				notifyWalletFailureToParent(e.message)
			})
	}, [clientId])

	useEffect(() => {
		if (!communicationHandler) {
			return
		}
		window.addEventListener('message', communicationHandler, false)
		return () => {
			window.removeEventListener('message', communicationHandler, false)
		}
	}, [communicationHandler])

	useEffect(() => {
		if (wallet != null) {
			notifyWalletReadyToParent(wallet.getAddress(), wallet.chainId)
		}
	}, [wallet])

	return (
		<div>
			<span>Web Wallet</span>
			<span>Client ID: {clientInfo?.client_name}</span>
			<span>Wallet Address: {wallet?.getAddress()}</span>
		</div>
	)
}

export default WebWallet
