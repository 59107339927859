import React from 'react'
import images from '../resources/images'

function Home() {
    return (
        <div
            className="bg-no-repeat bg-cover bg-center font-satoshi bg-white flex-grow w-full h-screen items-center flex justify-center flex-wrap flex-col"
        >
            <div
                className="w-96 text-center bg-white rounded-20 text-grey flex h-100 flex-col p-12 shadow-lg shadow-white">
                <img height="72" width="72" className="self-center mb-8" src={images.logo} alt="Cometh logo" />
                <h5>Hey there! Welcome to Cometh</h5>

                <div
                    className="h-12 border hover:scale-105 justify-evenly shadow-xl border-gray-50 mt-4 flex items-center">
                    <img src={images.logo} alt="Cometh logo" width="20px" height="20px" />
                    <p className="font-bold">Cometh OIDC provider</p>
                </div>
            </div>
        </div>
    )
}

export default Home