import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './views/login'
import Home from './views/home'
import WebWallet from './views/webWallet'

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/hub/login" element={<Login />} />
                <Route path="/hub/wallet" element={<WebWallet />} />
                <Route path="/hub" element={<Home />} />
            </Routes>
        </Router>
    )
}

export default App