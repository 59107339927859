import { useEffect, useState } from 'react'
import makeWallet from './makeWallet'
import { ComethWallet } from '@cometh/connect-sdk'
import api from '../sdk/api'
import { notifyWalletFailureToParent } from './useCommunicationHandler'
import { Logger } from './useCommunication.types'

function _getStorageKey(clientId: string) {
    return `walletAddress-${clientId}`
}

function _getAddress(clientId: string) {
    return window.localStorage.getItem(_getStorageKey(clientId)) || undefined
}

function _setAddress(clientId: string, address: string) {
    window.localStorage.setItem(_getStorageKey(clientId), address)
}

const useWallet = (chainId: string | null, clientId: string | null, walletAddress?: string, logger?: Logger): ComethWallet | null => {
    const [wallet, setWallet] = useState<ComethWallet | null>(null)

    useEffect(() => {
        if (chainId == null || clientId == null) {
            return
        }
        const storedAddress = walletAddress || _getAddress(clientId)
        api.getConnectConfig().then((config) => {
            const wallet = makeWallet(config.connectUrl, chainId, clientId)
            wallet.connect(storedAddress).then(() => {
                logger?.('Wallet connected')
                const walletAddress = wallet.getAddress()
                _setAddress(clientId, walletAddress)
                setWallet(wallet)
            }).catch((e) => {
                logger?.('Failed to connect wallet', true)
                notifyWalletFailureToParent(e.message)
            })
        }).catch((e) => {
            logger?.('Failed to fetch connect config', true)
            notifyWalletFailureToParent(e.message)
        })
    }, [chainId, clientId])

    return wallet
}

export default useWallet