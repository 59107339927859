import axios from 'axios'
import { ConnectConfig, PrivateClientInfo, PublicClientInfo } from './apiTypes'

const client = axios.create({
    baseURL: window.location.origin
})

const login = async (uid: string, walletAddress: string, message: string, signature: string) => {
    console.log('trying to login: ', uid, walletAddress, message, signature, window.location.origin)
    try {
        const response = await client.post(`/interaction/${uid}/signIn`, {
            walletAddress,
            message,
            signature,
        })
        console.log('login result: ', response)
        const finalUrl = response.request.responseURL
        if (finalUrl) {
            window.location.href = finalUrl
        }
    } catch (e) {
        console.error(e)
        throw e
    }
}

const confirmInteraction = async (uid: string) => {
    console.log('trying to confirm: ', uid)
    try {
        const response = await client.post(`/interaction/${uid}/confirm`, {})
        console.log('confirm result: ', response)
        const finalUrl = response.request.responseURL
        if (finalUrl) {
            window.location.href = finalUrl
        }
    } catch (e) {
        console.error(e)
        throw e
    }
}

const getPublicClientInfo = async (clientId: string): Promise<PublicClientInfo> => {
    console.log('trying to getClientInfo: ', clientId)
    try {
        const response = await client.get(`/clientinfo/${clientId}`)
        console.log('confirm result: ', response)
        return response.data
    } catch (e) {
        console.error(e)
        throw e
    }
}

const getPrivateClientInfo = async (clientId: string): Promise<PrivateClientInfo> => {
    try {
        const response = await client.get(`/me/clientinfo/${clientId}`)
        console.log('confirm result: ', response)
        return response.data
    } catch (e) {
        console.error(e)
        throw e
    }
}

const getConnectConfig = async (): Promise<ConnectConfig> => {
    try {
        const response = await client.get(`/config/connect`)
        console.log('confirm result: ', response)
        return response.data
    } catch (e) {
        console.error(e)
        throw e
    }
}

export default {
    login,
    confirmInteraction,
    getPublicClientInfo,
    getPrivateClientInfo,
    getConnectConfig
}