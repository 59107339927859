import { ComethProvider, ComethWallet } from '@cometh/connect-sdk'
import { useCallback } from 'react'
import {
	EventHandler,
	EventRequestDTO,
	EventResponseDTO,
	EventType,
	Logger,
	MessageEventResp,
	RequestGetTransactionDTO,
	RequestSendTransactionDTO,
	RequestSignMessageDTO,
	ResponseGetTransactionDTO,
	ResponseSendTransactionDTO,
	ResponseSignMessageDTO,
	ResponseWalletDTO
} from './useCommunication.types'
import { PrivateClientInfo } from '../sdk/apiTypes'
import { isValidObject, isValidString } from '../utils/validators'

const _signMessage = async (
	logger: Logger,
	wallet: ComethWallet,
	data: RequestSignMessageDTO
): Promise<ResponseSignMessageDTO> => {
	logger('Signing message: ' + data.message)
	const signature = await wallet.signMessage(data.message)
	return { message: data.message, signature }
}

const _signMessageValidator = (data: any) => {
	if (!isValidObject(data)) return false
	return isValidString(data.message)
}

const _sendTransaction = async (
	logger: Logger,
	wallet: ComethWallet,
	data: RequestSendTransactionDTO
): Promise<ResponseSendTransactionDTO> => {
	logger('Sending TX: ' + data.metaTransaction)
	const response = await wallet.sendTransaction(data.metaTransaction)
	return { safeTxHash: response.safeTxHash }
}

const _sendTransactionValidator = (data: any) => {
	if (!isValidObject(data) || !isValidObject(data.metaTransaction)) return false
	return (
		isValidString(data.metaTransaction.to) &&
		isValidString(data.metaTransaction.value) &&
		isValidString(data.metaTransaction.data)
	)
}

const _getTransaction = async (
	logger: Logger,
	wallet: ComethWallet,
	data: RequestGetTransactionDTO
): Promise<ResponseGetTransactionDTO> => {
	logger('Get TX: ' + data.safeTxHash)
	const provider = new ComethProvider(wallet)
	const txPending = await provider.getTransaction(data.safeTxHash)
	const txReceipt = await txPending.wait()
	return { txReceipt }
}

const _getTransactionValidator = (data: any) => {
	if (!isValidObject(data)) return false
	return isValidString(data.safeTxHash)
}

const _events: { [key: string]: EventHandler } = {
	SIGN_MESSAGE: {
		action: _signMessage,
		validator: _signMessageValidator
	},
	SEND_TRANSACTION: {
		action: _sendTransaction,
		validator: _sendTransactionValidator
	},
	GET_TRANSACTION: {
		action: _getTransaction,
		validator: _getTransactionValidator
	}
}

const _validateEventType = (event: any) => {
	if (event == null || typeof event !== 'string') return false
	return event in _events
}

const _validateEvent = (
	event: MessageEvent
): { request: EventRequestDTO; handler: EventHandler } => {
	console.log('event', event)
	if (
		event.data == null ||
		typeof event.data !== 'object' ||
		event.data.type == null ||
		event.data.data == null
	)
		throw Error('Invalid Event format')
	if (!_validateEventType(event.data.type)) {
		throw Error('Invalid Event request type')
	}
	const handler = _events[event.data.type]
	if (!handler.validator(event.data.data)) {
		throw Error('Invalid payload')
	}
	return {
		request: {
			data: event.data.data,
			type: event.data.type
		},
		handler
	}
}

const _formatError = (error: any) => {
	if (error instanceof Error) {
		return error.message
	}
	if (typeof error === 'object') {
		return error.message || JSON.stringify(error)
	}
	return error
}

const _error = (logger: Logger, event: MessageEventResp, error: any) => {
	const response = _formatResponse(event.data.type, { message: _formatError(error) }, false)
	if (event.source && event.origin != window.location.origin) {
		logger(`Error: ${JSON.stringify(response)}`, true)
		event.source.postMessage(response, { targetOrigin: '*' })
	} else {
		logger(`No source to process the error response: ${JSON.stringify(response)}`, true)
	}
}

const _success = (logger: Logger, event: MessageEventResp, result: any) => {
	const response = _formatResponse(event.data.type, result)
	if (event.source) {
		logger(`success:${JSON.stringify(response)}`)
		event.source.postMessage(response, { targetOrigin: '*' })
	} else {
		logger(`No source to process the success response: ${JSON.stringify(response)}`)
	}
}

const useCommunicationHandler = (
	wallet: ComethWallet | null,
	client: PrivateClientInfo | null,
	logger: Logger
) => {
	const communicationHandler = useCallback(
		(event: MessageEvent) => {
			if (wallet == null || client == null) {
				return _error(logger, event, 'Handler not ready')
			}
			if (!client.domains.includes(event.origin)) {
				return _error(logger, event, `Unauthorized origin (${event.origin})`)
			}

			try {
				const { request, handler } = _validateEvent(event)

				logger(`Processing event: ${JSON.stringify(request)}`)
				handler
					.action(logger, wallet, request.data)
					.then((data: any) => {
						_success(logger, event, data)
					})
					.catch((error: any) => {
						_error(logger, event, error)
					})
			} catch (error: any) {
				_error(logger, event, error)
			}
		},
		[wallet, client]
	)

	if (wallet == null || client == null) {
		return undefined
	}
	return communicationHandler
}

const _formatResponse = (type: EventType, data: any, success: boolean = true): EventResponseDTO => {
	return { type, data, success }
}

const _postCommunicationToParent = (type: EventType, data: any, success: boolean = true) => {
	window.parent.postMessage(_formatResponse(type, data, success), '*')
}

export const notifyWalletReadyToParent = (address: string, chainId: number) => {
	const response: ResponseWalletDTO = { address, chainId }
	_postCommunicationToParent(EventType.WALLET_READY, response)
}

export const notifyWalletFailureToParent = (error: string) => {
	_postCommunicationToParent(EventType.WALLET_READY, { message: _formatError(error) }, false)
}

export default useCommunicationHandler
