import { ComethWallet, ConnectAdaptor } from '@cometh/connect-sdk'
import { SupportedNetworks } from '@cometh/connect-sdk/dist/wallet/types'

function makeWallet(connectApiUrl: string, chainId: string, clientApiKey: string) {
	const signerAdaptor = new ConnectAdaptor({
		chainId: chainId as SupportedNetworks,
		apiKey: clientApiKey,
		baseUrl: connectApiUrl
	})
	return new ComethWallet({
		authAdapter: signerAdaptor,
		apiKey: clientApiKey,
		baseUrl: connectApiUrl,
		uiConfig: {
			displayValidationModal: false
		}
	})
}

export default makeWallet
